import { createStore } from "vuex";
import { userModule } from "./modules/userModule";
import { adminUsersModule } from "./modules/adminUsersModule";
import { managerSlider } from "./modules/managerSlider";
export default createStore({
  modules: {
    user: userModule,
    users: adminUsersModule,
    slider: managerSlider,
  },
});
