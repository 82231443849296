<template>
  <router-view />
</template>
<script>
import jwt_decode from "jwt-decode";

export default {
  mounted() {
    document.title = "SocialFeed v2";
    const isUserLoggedIn = this.$cookies.get(
      process.env.VUE_APP_LOGIN_COOKIE_NAME
    );
    if (isUserLoggedIn) {
      this.$store.dispatch("loginUser");
      this.$store.dispatch("setAuthToken", isUserLoggedIn);
      const decoded = jwt_decode(isUserLoggedIn);
      this.$store.dispatch("setCurrentUserRole", decoded.role);
      this.$router.push(`/dashboard/${decoded.role}`);
    }
  },
};
</script>
<style>
#app {
  min-width: 100vw;
  min-height: 100vh;
}
</style>
