<template>
  <div class="container mt-5">
    <div>
      <h2 class="mb-5">Moje fotografije</h2>

      <div class="gallery">
        <div class="mb-3" v-for="media in localMediaList" :key="media.id">
          <div class="gallery-item">
            <img
              style="object-fit: cover; width: 100%; height: 100%"
              v-if="media.featured_image.slug"
              :src="domainUrl + '/' + media.featured_image.slug"
              alt=""
            />
            <img
              style="object-fit: cover; width: 100%; height: 100%"
              v-else
              :src="domainUrl + '/' + media.image_slug"
              alt=""
            />
            <div class="delete-post">
              <CButton
                color="danger"
                class="text-light btn-full"
                @click="
                  () => {
                    deletePost(media.id, 'custom');
                  }
                "
                >Obriši</CButton
              >
            </div>
            <div class="show-hide p-1">
              <CButton
                :color="!media.visible ? 'info' : 'danger'"
                class="text-light btn-full"
                @click="
                  () => {
                    media.visible = !media.visible;
                    changeImageStatus(
                      media.id,
                      media.visible,
                      media.featured_image_id,
                      media.repeat
                    );
                  }
                "
                >{{ !media.visible ? "Prikaži" : "Sakrij" }}</CButton
              >
            </div>
          </div>
          <CFormInput
            class="mt-1"
            @blur="
              (event) =>
                updateImageRepeatPosition(
                  event,
                  media.id,
                  media.featured_image_id
                )
            "
            type="number"
            min="0"
            id="position"
            placeholder="Ponavljanja u slajderu (broj)"
            required
            :value="media.repeat"
          />
        </div>
      </div>
      <CButton
        color="primary"
        @click="openModal = !openModal"
        class="me-auto mb-3"
        >Dodaj fotografiju</CButton
      >
    </div>
    <h2 class="mt-5">Instagram fotografije / snimci</h2>
    <draggable
      v-if="list.length > 0"
      :list="list"
      :disabled="!enabled"
      item-key="name"
      class="gallery"
      ghost-class="ghost"
      :move="checkMove"
      @start="dragging = true"
      @end="dragging = true"
    >
      <template #item="{ element }">
        <div
          :style="
            element.visible
              ? 'border: 3px solid #61E500;'
              : 'border: 3px solid #A41A1A;'
          "
          class="gallery-item"
          :class="{ 'not-draggable': !enabled }"
        >
          <div class="delete-post">
            <CButton
              color="danger"
              class="text-light btn-full"
              @click="
                () => {
                  deletePost(element.id, 'list');
                }
              "
              >Obriši</CButton
            >
          </div>
          <span v-if="element.type === 'IMAGE'">
            <img :src="element.link" class="gallery-image" alt="" />
          </span>
          <span v-else>
            <video controls autoplay loop muted class="video-fix">
              <source :src="element.link" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </span>
          <div class="gallery-item-info"></div>
          <div class="show-hide p-1">
            <CButton
              :color="!element.visible ? 'info' : 'danger'"
              class="text-light btn-full"
              @click="
                () => {
                  element.visible = !element.visible;
                  changeImageStatus(element.id, element.visible);
                }
              "
              >{{ !element.visible ? "Prikaži" : "Sakrij" }}</CButton
            >
          </div>
        </div>
      </template>
    </draggable>
    <div v-else>
      <div class="d-flex justify-content-center">
        <CSpinner class="my-3" />
      </div>
      <p class="text-center">Učitavanje Instagram fotografija...</p>
    </div>
    <div class="d-flex justify-content-center mt-5">
      <CButton
        color="primary"
        class="text-light"
        v-if="loadMoreInstaFeed"
        @click="loadMoreInstaFeedImages"
        >Učitaj još</CButton
      >
    </div>
  </div>
  <CModal
    alignment="center"
    :visible="openModal"
    @close="
      () => {
        openModal = false;
      }
    "
  >
    <CModalHeader>
      <CModalTitle>Dodaj fotografiju</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="mb-3">
        <input
          type="file"
          @change="previewFiles"
          accept="image/png, image/jpeg"
        />
      </div>
    </CModalBody>
    <CModalFooter>
      <CButton
        color="secondary"
        class="text-light"
        @click="
          () => {
            openModal = false;
            this.startUploadImage = false;
          }
        "
      >
        Zatvori
      </CButton>
      <CButton
        :disabled="!isImageInInput"
        @click="createCustomPost"
        color="primary"
        ><CSpinner
          v-if="startUploadImage"
          component="span"
          size="sm"
          aria-hidden="true"
        />Dodaj novu fotografiju</CButton
      >
    </CModalFooter>
  </CModal>
  <CToast
    v-if="repeatUpdated"
    :autohide="true"
    color="success"
    class="text-white align-items-center toast-fixed"
  >
    <div class="d-flex">
      <CToastBody>Čestitamo! Upravo ste uspešno promenili broj!</CToastBody>
      <CToastClose class="me-2 m-auto" white />
    </div>
  </CToast>
</template>

<script>
import draggable from "vuedraggable";
import {
  createSliderImage,
  fetchInstagramFeed,
  fetchLocalFeed,
  fetchMoreInstagramFeed,
  updateSelectImageStatus,
  updateImageRepeat,
  deletePost,
} from "../../helpers/apiCalls";
import {
  CButton,
  CModal,
  CModalFooter,
  CModalBody,
  CModalTitle,
  CModalHeader,
  CFormInput,
  CSpinner,
  CToast,
  CToastBody,
  CToastClose,
} from "@coreui/vue";
export default {
  components: {
    draggable,
    CButton,
    CModal,
    CModalFooter,
    CModalBody,
    CModalTitle,
    CModalHeader,
    CFormInput,
    CSpinner,
    CToast,
    CToastBody,
    CToastClose,
  },
  data() {
    return {
      enabled: false,
      list: [],
      localMediaList: [],
      dragging: false,
      saveSlider: false,
      openModal: false,
      startUploadImage: false,
      isImageInInput: false,
      image: "",
      loadMoreInstaFeed: false,
      currentPage: 2,
      repeatUpdated: false,
    };
  },
  async mounted() {
    const instaFeed = await fetchInstagramFeed();
    instaFeed.data.data.map((img) => {
      this.list.push({ ...img, visible: img.status.id === 2 ? true : false });
    });
    console.log(this.list);
    if (instaFeed.data.next_page_url) {
      this.loadMoreInstaFeed = true;
    }
    const {
      data: { data: data },
    } = await fetchLocalFeed();
    if (data) {
      data.map((img) =>
        this.localMediaList.push({
          ...img,
          visible: img.status.id === 2 ? true : false,
        })
      );
    }
  },
  methods: {
    async updateImageRepeatPosition(e, mediaId, fe_img_id) {
      await updateImageRepeat(mediaId, e.target.value, fe_img_id);
      this.$router.go(this.$router.currentRoute);
      this.repeatUpdated = true;
    },
    async loadMoreInstaFeedImages() {
      const newImages = await fetchMoreInstagramFeed(this.currentPage);
      newImages.data.data.map((img) =>
        this.list.push({ ...img, visible: img.status.id === 2 ? true : false })
      );
      this.currentPage++;
      if (!newImages.data.next_page_url) {
        this.loadMoreInstaFeed = false;
      }
    },
    checkMove() {
      this.saveSlider = true;
    },
    previewFiles(event) {
      this.image = event.target.files;
      this.isImageInInput = true;
    },
    async createCustomPost() {
      const res = await createSliderImage({
        repeat: 0,
        featured_image: this.image[0],
      });
      console.log(res);
      this.localMediaList.push({
        ...res.data.post,
        image_slug: res.data.image_slug,
      });
      this.$router.go(this.$router.currentRoute);
    },
    async changeImageStatus(
      id,
      status,
      featured_image = null,
      repeat_id = null
    ) {
      if (featured_image !== null) {
        await updateSelectImageStatus(id, status, featured_image, repeat_id);
      } else {
        await updateSelectImageStatus(id, status);
      }
    },
    async deletePost(postId, type) {
      await deletePost(postId);
      if (type === "list") {
        this.list = this.list.filter((list) => list.id != postId);
      } else {
        this.localMediaList = this.localMediaList.filter(
          (list) => list.id != postId
        );
      }
    },
  },
  computed: {
    domainUrl() {
      return process.env.VUE_APP_MEDIA_DOMAIN;
    },
  },
};
</script>
<style scoped>
.delete-post {
  position: absolute;
  z-index: 9;
  top: 10px;
  right: 10px;
}
.toast-fixed {
  position: fixed;
  z-index: 9;
  bottom: 10px;
  right: 10px;
}
.gallery-item {
  position: relative;
}
.show-hide {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
.video-fix {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.not-draggable {
  cursor: no-drop;
}

.gallery-item {
  position: relative;
  flex: 1 0 20%;
  color: #fff;
  cursor: pointer;
  height: 250px;
}
.gallery-item:hover .gallery-item-info,
.gallery-item:focus .gallery-item-info {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.gallery-item-type {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2.5rem;
  text-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
}
.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@supports (display: grid) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    grid-gap: 0.5rem;
  }
  .gallery {
    width: auto;
    margin: 0;
  }
}
@media (max-width: 991px) {
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
  }
}
@media (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }
}
.btn-full {
  width: 100%;
}
</style>
