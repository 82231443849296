<template>
  <div v-if="!newSlides" class="fetch-slides"><CSpinner class="spinner" /></div>
  <Carousel v-if="isSlideLoad" :autoplay="6000" :wrap-around="true">
    <Slide v-for="slide in sliders" :key="slide.id">
      <img
        v-if="slide.link !== null"
        class="full-width-img"
        :src="slide.link"
      />
      <img
        v-else
        class="full-width-img"
        :src="domainUrl + '/' + slide.featured_image.slug"
      />
    </Slide>
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide } from "vue3-carousel";
import { CSpinner } from "@coreui/vue";
import Pusher from "pusher-js";
import "vue3-carousel/dist/carousel.css";
import { fetchSlideItems } from "../helpers/apiCalls";
export default defineComponent({
  name: "Slider",
  components: {
    Carousel,
    Slide,
    CSpinner,
  },
  data() {
    return {
      isSlideLoad: false,
      sliders: [],
      newSlides: false,
    };
  },
  async mounted() {
    const pusher = new Pusher("687e48b6464e1d0a7adc", {
      cluster: "eu",
    });
    const sliderId = this.$route.params.customerId;
    const { data } = await fetchSlideItems(sliderId);
    this.sliders = data;
    this.isSlideLoad = true;
    this.newSlides = true;
    const channel = pusher.subscribe("my-channel");
    channel.bind("my-event", async function () {
      const { data } = await fetchSlideItems(sliderId);
      this.sliders = [];
      this.sliders = data;
      this.newSlides = false;
    });
  },
  computed: {
    domainUrl() {
      return process.env.VUE_APP_MEDIA_DOMAIN;
    },
  },
});
</script>

<style scoped>
.fetch-slides {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  z-index: 99;
}
.spinner {
  margin: auto;
}
.carousel {
  background-color: black !important;
}
.full-width-img {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}
</style>
