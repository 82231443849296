<template>
  <CForm class="overflow-scroll" @submit.prevent="registerNewUser">
    <div class="row">
      <div class="mb-3 col-6">
        <CFormLabel for="email">Email adresa</CFormLabel>
        <CFormInput
          id="email"
          name="email"
          type="email"
          :value="newUser.email"
          placeholder="name@example.com"
          required
          v-model="newUser.email"
          ref="inputs"
        />
      </div>
      <div class="mb-3 col-6">
        <CFormLabel for="password">Lozinka</CFormLabel>
        <CFormInput
          id="password"
          required
          v-model="newUser.password"
          name="password"
          type="password"
        />
      </div>
    </div>
    <div class="mb-3">
      <CFormLabel for="username">Ime</CFormLabel>
      <CFormInput
        id="username"
        name="username"
        required
        v-model="newUser.name"
        type="text"
      />
    </div>
    <div class="mb-3">
      <CFormLabel for="insta_token">Access token</CFormLabel>
      <CFormTextarea
        id="insta_token"
        name="insta_token"
        v-model="newUser.access_token"
        rows="3"
        required
      />
    </div>
    <div class="row">
      <div class="mb-3 col-6">
        <CFormLabel for="fb_page_id">App Id</CFormLabel>
        <CFormInput
          id="fb_page_id"
          v-model="newUser.app_id"
          name="fb_page_id"
          type="text"
          required
        />
      </div>
      <div class="mb-3 col-6">
        <CFormLabel for="insta_profile_id">App Secret</CFormLabel>
        <CFormInput
          id="app_secret"
          v-model="newUser.app_secret"
          name="app_secret"
          type="text"
          required
        />
      </div>
    </div>
    <div class="mb-3">
      <CFormLabel for="insta_profile_id">Pozicija</CFormLabel>
      <CFormSelect
        v-model="newUser.role_id"
        aria-label="Default select example"
      >
        <option value="1">Administrator</option>
        <option value="2" selected>Manager</option>
      </CFormSelect>
    </div>
    <div class="mb-3">
      <CFormLabel for="insta_profile_id">Hashtag</CFormLabel>
      <CFormInput
        id="hashtag"
        v-model="newUser.instagram_hashtag_id"
        name="instagram_hashtag_id"
        type="text"
        required
      />
    </div>
    <div class="d-grid gap-2">
      <CButton type="submit" color="primary" class="d-block mb-3"
        >Kreiraj nalog</CButton
      >
    </div>
  </CForm>
  <CToast
    v-if="registerError"
    :autohide="true"
    color="danger"
    class="text-white align-items-center login-error-box fixed-toast"
  >
    <div class="d-flex">
      <CToastBody>{{ registerErrorResponse }}</CToastBody>
      <CToastClose class="me-2 m-auto" white />
    </div>
  </CToast>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import { createNewUser } from "../helpers/apiCalls";
import {
  CForm,
  CFormLabel,
  CFormInput,
  CFormTextarea,
  CButton,
  CFormSelect,
  CToast,
  CToastBody,
  CToastClose,
} from "@coreui/vue";
export default {
  components: {
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CButton,
    CFormSelect,
    CToast,
    CToastBody,
    CToastClose,
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const store = useStore();
    const registerError = ref(false);
    const registerErrorResponse = ref("");
    const newUser = ref({
      name: "",
      email: "",
      password: "",
      access_token: "",
      app_id: "",
      app_secret: "",
      role_id: "2",
      instagram_hashtag_id: "",
    });
    const closeModal = () => {
      emit("closeModal");
    };
    const registerNewUser = async () => {
      try {
        const responseNewUser = await createNewUser(newUser.value);
        store.dispatch("addNewUserToList", responseNewUser.data.user_object);
        closeModal();
      } catch (error) {
        registerError.value = true;
        registerErrorResponse.value = error;
      }
    };
    return {
      registerNewUser,
      newUser,
      registerError,
      registerErrorResponse,
      closeModal,
    };
  },
};
</script>
<style scoped>
.fixed-toast {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
</style>
